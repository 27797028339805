import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ClusterDataTO, JobTypeTO, LabelTO } from '../api/api.types';
import useSettings from './SettingsContext';
import { useUserContext } from './Users';
import { useBatchLabelingContext } from './BatchLabelingProcess';
import {
  assignStationCluster,
  ClusterAction,
  getClusterAction,
} from '../datalayer/dataLayer';
import { HttpStatus } from '../enums';
import useJobTypes from '../helpers/useJobTypes';
import { reorderPoints } from '../api/crops';
import { nextId } from '../utils';
import {
  Point,
  Cluster,
  StationStateContextProps,
} from './StationStateContext.types';
import { SubStateType, SubStateTypeKey } from '../enumsSubState';

export const MAIN_CLUSTER_ID = '0';
const MAIN_CLUSTER_NAME = 'Main';
const DEFAULT_CLUSTER = { id: MAIN_CLUSTER_ID, name: MAIN_CLUSTER_NAME };

const ERROR_MESSAGE = 'Sorry something went wrong';

const StationStateContext = createContext<StationStateContextProps | undefined>(
  undefined,
);

export const StationStateContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [contextStationType, setStationTypeContextState] =
    useState<SubStateTypeKey>();

  const [jobTypes, setJobTypes] = useState<JobTypeTO[]>([]);
  const [selectedJobType, setSelectedJobType] = useState<JobTypeTO | null>(
    null,
  );
  const [clusterData, setClusterData] = useState<ClusterDataTO | null>(null);
  const [clusters, setClusters] = useState<Cluster[]>([DEFAULT_CLUSTER]);
  const [selectedClusterId, setSelectedClusterId] =
    useState<string>(MAIN_CLUSTER_ID);
  const [crops, setCrops] = useState<Point[]>([]);
  const [selectedCrops, setSelectedCrops] = useState<{ [key: string]: any }>(
    {},
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isRejected, setIsRejected] = useState(false);
  const [cropViewId, setCropViewId] = useState<string | undefined>();
  const [shelfViewCropId, setShelfViewCropId] = useState<string | null>(null);
  const { isAutoLoadOn, setAutoLoad, isLabelerMode, setLabelerMode } =
    useSettings();
  const { isLabeler, assignJobTypes } = useUserContext();
  const { handleJobTypeChange } = useBatchLabelingContext();
  const [showRemoveClusterModal, setShowRemoveClusterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastRejectComment, setLastRejectComment] = useState('');
  const [rejectListExpanded, setRejectListExpanded] = useState(false);
  const [nextDraftClusterNumber, setNextDraftClusterNumber] = useState(1);
  const [selectedLabel, setSelectedLabel] = useState<LabelTO | null>(null);

  const clearClusters = useCallback(() => {
    setClusters([DEFAULT_CLUSTER]);
    setNextDraftClusterNumber(1);
    setSelectedClusterId(MAIN_CLUSTER_ID);
  }, []);

  const clearData = useCallback(() => {
    setClusterData(null);
    // setSelectedJobType(null);
    setCrops([]);
    setSelectedCrops({});
    clearClusters();
    setIsRejected(false);
    setRejectListExpanded(false);
    setLastRejectComment('');
    setErrorMessage('');
    setSelectedLabel(null);
  }, [clearClusters]);

  const assignLabeler = useCallback(
    async (jobType: JobTypeTO | null) => {
      if (!jobType) {
        setLoading(false);
        return;
      }
      setLoading(true);
      setSelectedJobType(jobType);
      setIsRejected(false);
      setRejectListExpanded(false);
      setLastRejectComment('');
      setErrorMessage('');
      try {
        const { status, data } = await assignStationCluster(
          contextStationType,
          isLabeler || isLabelerMode,
          jobType.project,
        );
        switch (status) {
          case HttpStatus.SUCCESS:
            const isNewCluster = clusterData?.id !== data.id;
            if (isNewCluster) {
              setSelectedJobType(jobType);
              setSelectedCrops({});
              clearClusters();
            }
            const clustersToKeep: any = {};

            setCrops((prevCrops) => {
              return data.points.map((point, index) => {
                const prevCrop = isNewCluster
                  ? undefined
                  : prevCrops.find((c) => c.id === point.id);
                if (prevCrop?.clusterId) {
                  clustersToKeep[prevCrop.clusterId] = true;
                }
                return {
                  ...point,
                  clusterId: prevCrop?.clusterId ?? MAIN_CLUSTER_ID,
                  sortNumber: prevCrop?.sortNumber ?? index, // TODO: is there a better way to keep crops sorted? Keeped this for same UX as on prev UI
                };
              });
            });
            if (!isNewCluster) {
              setClusters((prevClusters) =>
                prevClusters.filter(
                  ({ id }) => id === MAIN_CLUSTER_ID || clustersToKeep[id],
                ),
              );

              if (!clustersToKeep[selectedClusterId])
                setSelectedClusterId(MAIN_CLUSTER_ID);
            }

            setIsRejected(data.reject_flag);
            setClusterData(data);
            break;
          case HttpStatus.NO_CONTENT:
            clearData();
            setErrorMessage('There are no clusters to proceed');
            break;
          default:
            clearData();
            setErrorMessage(data.detail || '');
            break;
        }
      } catch (error) {
        console.error('Error assigning user:', error);
      } finally {
        setLoading(false);
      }
    },
    [
      clearData,
      clusterData?.id,
      isLabeler,
      isLabelerMode,
      clearClusters,
      selectedClusterId,
      contextStationType,
    ],
  );

  useJobTypes(setLoading, setJobTypes);

  // Auto setting job type for labeler
  useEffect(() => {
    if (
      !assignJobTypes.length ||
      contextStationType === SubStateType.FAST_LANE ||
      !isLabeler
    )
      return;
    const assignedJobType = jobTypes.find(
      (jobType) => jobType.id === assignJobTypes[0],
    );
    if (!assignedJobType) return;
    setSelectedJobType(assignedJobType);
    assignLabeler(assignedJobType);
  }, [assignJobTypes, assignLabeler, jobTypes, contextStationType, isLabeler]);

  const refreshData = useCallback(async () => {
    if (!isAutoLoadOn) {
      clearData();
      setLoading(false);
      return;
    }
    await assignLabeler(selectedJobType);
    setSelectedCrops({});
  }, [isAutoLoadOn, selectedJobType, assignLabeler, clearData]);

  // TODO: TS me?
  const checkResponse = useCallback(
    async (response: any, keepClusterData = false) => {
      if (response.status === HttpStatus.SUCCESS) {
        if (!keepClusterData) await refreshData();
      } else {
        return await response
          .json()
          .then((data: any) => setErrorMessage(data.detail || ERROR_MESSAGE))
          .catch(() => {
            setErrorMessage(ERROR_MESSAGE);
            return Promise.reject('error');
          });
      }
    },
    [refreshData],
  );

  const clusterCrops = useMemo(
    () =>
      Object.fromEntries(
        clusters.map((cluster) => [
          cluster.id,
          crops
            .filter((crop) => crop.clusterId === cluster.id)
            .sort((a, b) => a.sortNumber - b.sortNumber),
        ]),
      ),
    [clusters, crops],
  );

  const selectedClusterSelectedCrops: Point[] = useMemo(
    () =>
      (clusterCrops[selectedClusterId] ?? []).filter(
        ({ id }) => selectedCrops[id],
      ),
    [clusterCrops, selectedClusterId, selectedCrops],
  );

  const hasMarkedCrops: boolean = useMemo(
    () => !!crops.find(({ marked_for_action }) => marked_for_action),
    [crops],
  );

  const onSelectJobType = useCallback(
    (jobType: JobTypeTO) => {
      assignLabeler(jobType);
      handleJobTypeChange(jobType);
    },
    [assignLabeler, handleJobTypeChange],
  );

  const onSelectLabelerMode = useCallback(
    (mode: boolean) => {
      setLabelerMode(mode);
      setSelectedJobType(null);
      clearData();
    },
    [setLabelerMode, setSelectedJobType, clearData],
  );

  const onSelectCrop = useCallback(
    (cropId: string) => {
      if (selectedCrops[cropId]) {
        const updSelectedCrops = { ...selectedCrops };
        delete updSelectedCrops[cropId];
        setSelectedCrops(updSelectedCrops);
      } else {
        setSelectedCrops({ ...selectedCrops, [cropId]: true });
      }
    },
    [selectedCrops],
  );

  const onUpdateSelections = useCallback(
    (type: 'allBelow' | 'unselectAll' | 'invert') => {
      const updSelectedCrops = { ...selectedCrops };
      switch (type) {
        case 'allBelow':
          if (!selectedClusterSelectedCrops.length) return;
          const maxSelectedSortNumber =
            selectedClusterSelectedCrops[
              selectedClusterSelectedCrops.length - 1
            ].sortNumber;
          (clusterCrops[selectedClusterId] ?? [])
            .filter(({ sortNumber }) => sortNumber > maxSelectedSortNumber)
            .forEach(({ id }) => {
              updSelectedCrops[id] = true;
            });
          break;
        case 'unselectAll':
          selectedClusterSelectedCrops.forEach(({ id }) => {
            delete updSelectedCrops[id];
          });
          break;
        case 'invert':
          (clusterCrops[selectedClusterId] ?? []).forEach(({ id }) => {
            if (selectedCrops[id]) delete updSelectedCrops[id];
            else updSelectedCrops[id] = true;
          });
          break;
      }
      setSelectedCrops(updSelectedCrops);
    },
    [
      selectedClusterSelectedCrops,
      clusterCrops,
      selectedClusterId,
      selectedCrops,
    ],
  );

  const onSortBySelected = useCallback(async () => {
    setLoading(true);
    const cropForSort = crops
      .filter(
        ({ id, clusterId }) =>
          selectedCrops[id] && clusterId === MAIN_CLUSTER_ID,
      )
      .map(({ id }) => id);
    const props = {
      points: cropForSort,
    };
    const response = await reorderPoints(clusterData?.id, props);

    if (response.status === 200) {
      await response
        .json()
        .then((data) => {
          const cropSorter = data.point_ids;
          const selectedCropsLength = Object.keys(selectedCrops).length;
          setCrops(
            crops.map((crop) => {
              const updatedCrop = { ...crop };
              if (selectedCrops[crop.id]) {
                updatedCrop.sortNumber = cropForSort.indexOf(crop.id);
              } else if (cropSorter.includes(crop.id)) {
                updatedCrop.sortNumber =
                  selectedCropsLength + cropSorter.indexOf(crop.id);
              }
              return updatedCrop;
            }),
          );
        })
        .catch(() => setErrorMessage(ERROR_MESSAGE));
    } else {
      setErrorMessage(ERROR_MESSAGE);
    }
    setLoading(false);
  }, [crops, clusterData?.id, selectedCrops]);

  const onMoveToCluster = useCallback(
    (id: string, updatedClusters = clusters) => {
      const updatedCrops = crops.map((crop) =>
        crop.clusterId === selectedClusterId && selectedCrops[crop.id]
          ? { ...crop, clusterId: id }
          : crop,
      );
      setCrops(updatedCrops);
      const filteredClusters = updatedClusters.filter((cluster: Cluster) =>
        updatedCrops.find(({ clusterId }) => cluster.id === clusterId),
      );
      setClusters(filteredClusters);
      if (
        !filteredClusters.find(
          (cluster: Cluster) => cluster.id === selectedClusterId,
        )
      ) {
        setSelectedClusterId(id);
      }
      onUpdateSelections('unselectAll');
    },
    [crops, clusters, onUpdateSelections, selectedClusterId, selectedCrops],
  );

  const onMoveToNewCluster = useCallback(
    (name: string) => {
      const newCluster: Cluster = { id: nextId().toString(), name };
      const updatedClusters = [...clusters, newCluster];
      onMoveToCluster(newCluster.id, updatedClusters);
      setNextDraftClusterNumber((prev) => prev + 1);
    },
    [clusters, onMoveToCluster],
  );

  const performClusterAction = useCallback(
    async (
      action: ClusterAction,
      comment?: string,
      cropsList?: Point[],
      keepClusterData: boolean = false,
    ) => {
      if (loading) return;
      setLoading(true);
      if (!clusterData) return;
      return await checkResponse(
        await getClusterAction(action, clusterData.id, {
          fix:
            contextStationType === SubStateType.FAST_LANE ||
            !(isLabeler || isLabelerMode),
          comment,
          cropIds: (cropsList ?? selectedClusterSelectedCrops).map(
            ({ id }) => id,
          ),
          clusterCrops,
        }),
        keepClusterData,
      );
    },
    [
      loading,
      clusterData,
      contextStationType,
      isLabeler,
      isLabelerMode,
      selectedClusterSelectedCrops,
      checkResponse,
      clusterCrops,
    ],
  );

  const onResumeWork = useCallback(() => {
    setAutoLoad(true);
    if (!selectedJobType) return;
    assignLabeler(selectedJobType);
  }, [selectedJobType, setAutoLoad, assignLabeler]);

  const contextValue = useMemo(
    () => ({
      crops,
      selectedJobType,
      jobTypes,
      onSelectJobType,
      onResumeWork,
      errorMessage,
      isRejected,
      clusterData,
      clusters,
      setShowRemoveClusterModal,
      performClusterAction,
      hasMarkedCrops,
      lastRejectComment,
      setLastRejectComment,
      rejectListExpanded,
      setRejectListExpanded,
      setStationTypeContextState,
      loading,
      setLoading,
      refreshData,
      setErrorMessage,
      contextStationType,
      selectedClusterId,
      setSelectedClusterId,
      clusterCrops,
      selectedCrops,
      onSelectCrop,
      isAutoLoadOn,
      onUpdateSelections,
      onSortBySelected,
      selectedClusterSelectedCrops,
      nextDraftClusterNumber,
      onMoveToNewCluster,
      onMoveToCluster,
      cropViewId,
      onSelectLabelerMode,
      shelfViewCropId,
      setShelfViewCropId,
      showRemoveClusterModal,
      selectedLabel,
      setSelectedLabel,
      setCropViewId,
    }),
    [
      crops,
      selectedJobType,
      jobTypes,
      onSelectJobType,
      onResumeWork,
      errorMessage,
      isRejected,
      clusterData,
      clusters,
      setShowRemoveClusterModal,
      performClusterAction,
      hasMarkedCrops,
      lastRejectComment,
      setLastRejectComment,
      rejectListExpanded,
      setRejectListExpanded,
      setStationTypeContextState,
      loading,
      setLoading,
      refreshData,
      setErrorMessage,
      contextStationType,
      selectedClusterId,
      setSelectedClusterId,
      clusterCrops,
      selectedCrops,
      onSelectCrop,
      isAutoLoadOn,
      onUpdateSelections,
      onSortBySelected,
      selectedClusterSelectedCrops,
      nextDraftClusterNumber,
      onMoveToNewCluster,
      onMoveToCluster,
      cropViewId,
      onSelectLabelerMode,
      shelfViewCropId,
      setShelfViewCropId,
      showRemoveClusterModal,
      selectedLabel,
      setSelectedLabel,
      setCropViewId,
    ],
  );

  return (
    <StationStateContext.Provider value={contextValue}>
      {children}
    </StationStateContext.Provider>
  );
};

export const useStationStateContext = (): StationStateContextProps => {
  const context = useContext(StationStateContext);
  if (!context) {
    throw new Error('useStateContext must be used within a StateProvider');
  }
  return context;
};
